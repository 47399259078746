import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next"
import {
  Breadcrumb, BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/project/img1.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import primg5 from "../../assets/images/project/img5.jpg"
import primg6 from "../../assets/images/project/img6.jpg"
import primg7 from "../../assets/images/project/img7.jpg"
import primg8 from "../../assets/images/project/img8.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getProjectInterest } from "../../store/projects/actions"
class myProject extends Component {
  componentDidMount() {
    const { onGetProjectInterest } = this.props;
    onGetProjectInterest(localStorage.getItem('userId'));
}
  render() {
    const { interestDetails } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div className=" d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18"> My Interest </h4><Link to="/project-compare"> <Button className="btn btn-primary ms-4">Compare properties</Button></Link>
              </div>
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  <BreadcrumbItem>
                    <Link to="#">Project</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">My Interest</Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>


           

            

            <Row>
            {map(interestDetails, (project, projectkey) => (
              <Col sm="3" key={projectkey}>
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                        {project?.projectinfo?.projectMainImages[0]?.projectGalleryLink}
                        <img src={project?.projectinfo?.projectMainImages?.projectGalleryLink} className="img-fluid" />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">{project?.projectInfo?.city}</h5>
                        <h4 className="prd-title"><Link to={`/project-detail/` + project?.projectInfo?.id + "/" + project?.projectInfo?.projectId }>{project?.projectInfo?.projectName}</Link>  <span className="float-end"><input type="checkbox" className="project-checkbox" /><Link to="/project-compare"><i className="compare-ico bx bx-git-compare "></i></Link></span></h4>
                    </div>
                </div>

              </Col>
            ))}
            </Row>


           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
myProject.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetProjectInterest:PropTypes.func,
  interestDetails:PropTypes.array,
  loading:PropTypes.object
}

const mapStateToProps = ({ projects }) => (
    {
        interestDetails:projects.interestDetails,
        loading:projects.loading
  
    })

    const mapDispatchToProps = dispatch => ({
        
      onGetProjectInterest:(clientId) => dispatch(getProjectInterest(clientId))
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(myProject))

