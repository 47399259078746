import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";

import { GET_PROJECTS, GET_PROJECT_DETAIL, GET_PROJECT_FEATURE, GET_PROJECT_GALLERY, GET_PROJECT_INTEREST, GET_PROJECT_LINK, POST_PROJECT_INTEREST } from "./actionTypes"

import { getAllProjectAPI, getAllProjectDetailAPI, getProjectFeatApi, getProjectGalleryApi, getProjectLinkApi, getProjetInterestAPI, postProjetInterestAPI } from '../../helpers/backend_helper'

import { getProjectDetailFail, getProjectDetailSuccess, getProjectFeatureActionFail, getProjectFeatureActionSuccess, getProjectGallaryFail, getProjectGallarySuccess, getProjectInterestFail, getProjectInterestSuccess, getProjectLinkFail, getProjectLinkSuccess, getProjectsFail, getProjectsSuccess, postProjectInterestFail, postProjectInterestSuccess } from "./actions"



function* fetchAllProject({agentId}) {
    try {
      const response = yield call(getAllProjectAPI, agentId)
      yield put(getProjectsSuccess(response))
    } catch (error) {
      yield put(getProjectsFail(error))
    }
  }

  function* fetchProjectDetail({projectId}) {
    try {
      //console.log('hello');
      const response = yield call(getAllProjectDetailAPI, projectId)
      //console.log(response);
      yield put(getProjectDetailSuccess(response))
    } catch (error) {
      yield put(getProjectDetailFail(error))
    }
  }

  function* submitProjectInterest({ payload: { project, history } }) {
    try {
      //console.log(message);
      toast.loading("Please wait...")
      const response = yield call(postProjetInterestAPI, {
        projectId: parseInt(project.projectId),
        clientId: parseInt(project.clientId),
        agentId:parseInt(project.agentId),
        interestedProjectDate: new Date(),
        isActive:true

      })
      yield put(postProjectInterestSuccess(response))
      if (response.success == true) {
        toast.dismiss();
        toast.success("Thank for interested in this project");
      
      } else {
        toast.warn("Some Error. Please try after some time");
      }
    }
    catch (error) {
  
      yield put(postProjectInterestFail(error))
    }
  }

  function* fetchProjectInterest({clientId}) {
    try {
      const response = yield call(getProjetInterestAPI, clientId)
      yield put(getProjectInterestSuccess(response))
    } catch (error) {
      yield put(getProjectInterestFail(error))
    }
  }

  function* fetchFeatureTab({ projectId }) {
    try {
      //console.log(uuid);
      const response = yield call(getProjectFeatApi, projectId)
      yield put(getProjectFeatureActionSuccess(response))
    } catch (error) {
      yield put(getProjectFeatureActionFail(error))
    }
  }

  function* fetchProjectLink({ projectId }) {
    try {
      //console.log(uuid);
      const response = yield call(getProjectLinkApi, projectId)
      yield put(getProjectLinkSuccess(response))
    } catch (error) {
      yield put(getProjectLinkFail(error))
    }
  }
  function* fetchProjectGallery({ projectId }) {
    try {
      //console.log(uuid);
      const response = yield call(getProjectGalleryApi, projectId)
      yield put(getProjectGallarySuccess(response))
    } catch (error) {
      yield put(getProjectGallaryFail(error))
    }
  }
  function* projectsSaga() {
    yield takeEvery(GET_PROJECTS, fetchAllProject)
    yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail)
    yield takeEvery(POST_PROJECT_INTEREST, submitProjectInterest)
    yield takeEvery(GET_PROJECT_INTEREST, fetchProjectInterest)
    yield takeEvery(GET_PROJECT_FEATURE, fetchFeatureTab)
    yield takeEvery(GET_PROJECT_LINK, fetchProjectLink)
    yield takeEvery(GET_PROJECT_GALLERY, fetchProjectGallery)
  }

  export default projectsSaga
