import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import { getProjectDetail, getProjectGallary, postProjectInterest } from "../../store/projects/actions"
import {
  Container,
  Row,
  Col,
  Card, 
  CardBody
} from "reactstrap"

class ProjectDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {  }
    this.handleInterestSubmit = this.handleInterestSubmit.bind(this)
  }
  handleInterestSubmit(value){
    
    //this.props.postProjectInterest(value, this.props.history);

  }
  
componentDidMount() {
  const { match: { params }, onGetProjectDetail }= this.props;
  if (params && params.id && params.projectId) {
    onGetProjectDetail(params.projectId);
  }else{
    //this.props.history.push('/my-project')
  }
}
  render() {
    const { projectDetails } = this.props;
    const mapStyles = {
      width: "100%",
      height: "250px"
    };
    const center = {
      lat: parseFloat(projectDetails?.projectSiteAddress?.latitude) || 0,
      lng: parseFloat(projectDetails?.projectSiteAddress?.longitude) || 0
    };
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t(projectDetails.projectName)}
            />
            
            <Row>
                <Col lg="8">
                <Card>
                <div className="mb-4 card-title-realty"><i className="mdi mdi-view-headline"></i> About</div>
                    <CardBody>
                    <div className="summery" dangerouslySetInnerHTML={{__html:projectDetails?.projectFeatures?.projectSummary}}></div>
                   
                    </CardBody>
                </Card>

                <Card>
                <div className="mb-4 card-title-realty"><i className="far fa-list-alt "></i> Facts</div>
                    <CardBody>
                    <ul className="extra-details testest">

                                    <li>
                        <div className="item-attr">Estimated Price Range</div>
                        <div className="item-property">${projectDetails.minPriceRange} - ${projectDetails.maxPriceRange}</div>
                    </li>
                                    <li>
                        <div className="item-attr">Type </div>
                        <div className="item-property">{projectDetails.projectType}</div>
                    </li>
                                    <li>
                        <div className="item-attr"># of Units</div>
                        <div className="item-property">{projectDetails.noOfUnits}</div>
                    </li>
                                    <li>
                        <div className="item-attr"># Stories</div>
                        <div className="item-property">{projectDetails.noOfStories}</div>
                    </li>
                                    <li>
                        <div className="item-attr">Compl. Month / Year </div>
                        <div className="item-property">{projectDetails.complitionMonth} / {projectDetails.complitionYear}</div>
                    </li>
                                    <li>
                        <div className="item-attr">Min Bedroom / Max Bedroom </div>
                        <div className="item-property">{projectDetails.minBed} / {projectDetails.maxBed}</div>
                    </li>
                
            </ul>
                   
                    </CardBody>
                </Card>
                </Col>
                <Col lg="4">
                            <Card>
                            <div className="mb-4 card-title-realty"><i className=" bx bx-map-alt"></i> Location</div>
                                <CardBody>
                                    <div className="contact-map">
                                    <div className="map-container">
                                      <Map
                                        google={google}
                                        zoom={15}
                                        initialCenter={center}
                                      >
                                        <Marker position={center} />
                                      </Map>
                                    </div>

                                    </div>
                                    <div className="map-block-address">
				                              <p>{projectDetails?.projectSiteAddress?.fullAddress}</p>
							
							                      </div>
                                </CardBody>
                            </Card>
                            <Card>
                <div className="mb-4 card-title-realty"><i className=" dripicons-ticket"></i> Amenities</div>
                    <CardBody>
                    <div className="summery" dangerouslySetInnerHTML={{__html:projectDetails?.projectFeatures?.projectHighlights}}></div>
                   
                    </CardBody>
                </Card>

                                <div className="d-grid">
                                <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                    projectId: projectDetails.id,
                    clientId: localStorage.getItem('userId'),
                    agentId: localStorage.getItem('agentId')

                  }}
                  validationSchema={Yup.object().shape({
                    
                  })}

                  onSubmit={this.handleInterestSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                                <button type="submit" className="btn-lg btn btn-label btn-danger"><i className="bx bx-heart label-icon"></i>I AM INTERESTED</button>

                                </Form>
                  )}
                </Formik>
                                </div>
                        </Col>
            </Row>
            
           
            
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ProjectDetail.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match:PropTypes.object,
    onGetProjectDetail:PropTypes.func,
    projectDetails:PropTypes.object  
  }
  const mapStateToProps = ({ projects }) => (
    {
      projectDetails:projects.projectDetails,
  
    })

    const mapDispatchToProps = dispatch => ({
      onGetProjectDetail:(projectId) => dispatch(getProjectDetail(projectId)),
      postProjectInterest: (data) => dispatch(postProjectInterest(data)),
      onGetProjectGallery:(projectId) => dispatch(getProjectGallary(projectId)),       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectDetail))
