import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_FEATURE,
  GET_PROJECT_FEATURE_ERROR,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_GALLERY,
  GET_PROJECT_GALLERY_ERROR,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_INTEREST,
  GET_PROJECT_INTEREST_FAIL,
  GET_PROJECT_INTEREST_SUCCESS,
  GET_PROJECT_LINK,
  GET_PROJECT_LINK_ERROR,
  GET_PROJECT_LINK_SUCCESS,
  POST_PROJECT_INTEREST,
  POST_PROJECT_INTEREST_FAIL,
  POST_PROJECT_INTEREST_SUCCESS,

} from "./actionTypes"

export const getProjects = agentId => (
  {
    type: GET_PROJECTS,
    agentId,
  })

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getProjectDetail = projectId => (
  //console.log(projectId),
  {  
  type: GET_PROJECT_DETAIL,
  projectId,
})

export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
})

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

export const postProjectInterest = (project, history) => ({
  type: POST_PROJECT_INTEREST,
  payload: { project, history }
})

export const postProjectInterestSuccess = project => ({
  type: POST_PROJECT_INTEREST_SUCCESS,
  payload: project
})

export const postProjectInterestFail = error => ({
  type: POST_PROJECT_INTEREST_FAIL,
  payload: error
})



export const getProjectInterest = clientId => (
  {  
  type: GET_PROJECT_INTEREST,
  clientId,
})

export const getProjectInterestSuccess = interestDetails => ({
  type: GET_PROJECT_INTEREST_SUCCESS,
  payload: interestDetails,
})

export const getProjectInterestFail = error => ({
  type: GET_PROJECT_INTEREST_FAIL,
  payload: error,
})


export const getProjectFeatureAction = (projectId) => ({
  type: GET_PROJECT_FEATURE,
  projectId
})

export const getProjectFeatureActionSuccess = projectFeature => ({
  type: GET_PROJECT_FEATURE_SUCCESS,
  payload: projectFeature,
})

export const getProjectFeatureActionFail = error => ({
  type: GET_PROJECT_FEATURE_ERROR,
  payload: error,
})


export const getProjectLink = (projectId) => (
  {
    type: GET_PROJECT_LINK,
    projectId
  })

export const getProjectLinkSuccess = links => ({
  type: GET_PROJECT_LINK_SUCCESS,
  payload: links,
})

export const getProjectLinkFail = error => ({
  type: GET_PROJECT_LINK_ERROR,
  payload: error,
})


export const getProjectGallary = (projectId) => (
  {
    type: GET_PROJECT_GALLERY,
    projectId
  })

export const getProjectGallarySuccess = images => ({
  type: GET_PROJECT_GALLERY_SUCCESS,
  payload: images,
})

export const getProjectGallaryFail = error => ({
  type: GET_PROJECT_GALLERY_ERROR,
  payload: error,
})