import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                     
                      to="/dashboard"
                    >
                      
                      {this.props.t(" Dashboard")} {this.props.menuOpen}
                     
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                  <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      
                      {this.props.t("Buyer")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/buyer-prefrences" className="dropdown-item">
                        {this.props.t("My preferences")}
                      </Link>
                      <Link to="/add-listing" className="dropdown-item">
                        {this.props.t("Listings")}
                      </Link>
                      <Link to="/buyers-checklist" className="dropdown-item">
                        {this.props.t("Checklist ")}
                      </Link>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              emailState: !this.state.emailState,
                            });
                          }}
                        >
                          {this.props.t("Tools")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.emailState,
                          })}
                        >
                         
                         
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/calculator"
                             
                            >
                              <span key="t-email-templates">Calculators</span>{" "}
                             
                            </Link>
                           
                          </div>
                          <Link to="/professional-services" className="dropdown-item">
                            {this.props.t("Professional Services")}
                          </Link>
                        </div>
                      </div>
                      </div>
                    
                  </li>


                  <li className="nav-item dropdown">
                  <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      
                      {this.props.t("Seller")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="sellers-checklist" className="dropdown-item">
                        {this.props.t("Checklist")}
                      </Link>
                      <Link to="/seller-showings" className="dropdown-item">
                        {this.props.t("Showings")}
                      </Link>
                     

                     
                      </div>
                    
                  </li>


                  <li className="nav-item dropdown">
                  <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      
                      {this.props.t("Projects")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/project" className="dropdown-item">
                        {this.props.t("All Project")}
                      </Link>
                      <Link to="/my-interests" className="dropdown-item">
                        {this.props.t("My Interests")}
                      </Link>
                      <Link to="/my-transection" className="dropdown-item">
                        {this.props.t("Transactions")}
                      </Link>

                      
                        
                      </div>
                      
                    
                  </li>


                  
                 
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                     
                      to="/messages"
                    >
                      
                      {this.props.t(" Messages")} {this.props.menuOpen}
                     
                    </Link>
                    
                  </li>


                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                     
                      to="/documents"
                    >
                      
                      {this.props.t(" Documents")} {this.props.menuOpen}
                     
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                     
                      to="/appointment"
                    >
                      
                      {this.props.t(" Appointments")} {this.props.menuOpen}
                     
                    </Link>
                    
                  </li>


                  <li className="nav-item dropdown">
                  <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      
                      {this.props.t("Settings")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/profile" className="dropdown-item">
                        {this.props.t("Profile")}
                      </Link>
                      <Link to="/my-lawyers" className="dropdown-item">
                        {this.props.t("My Professional Contacts")}
                      </Link>
                      
                      

                     
                      </div>
                    
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
