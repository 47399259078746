import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/no-img.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import primg5 from "../../assets/images/project/img5.jpg"
import primg6 from "../../assets/images/project/img6.jpg"
import primg7 from "../../assets/images/project/img7.jpg"
import primg8 from "../../assets/images/project/img8.jpg"
import primg9 from "../../assets/images/project/img1.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getProjects } from "../../store/projects/actions"


class Project extends Component {

  componentDidMount() {
    const { onGetProject } = this.props;
    onGetProject(localStorage.getItem('agentId'));
}

  render() {
    console.log(localStorage.getItem('authUser'));
    const { projects } = this.props;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title="Projects"
              breadcrumbItem="Projects"
            />
            <h4>Currently Selling</h4>

<Row>
{map(projects, (project, projectkey) => (
  <Col sm="3" key={"_note_" + projectkey}>
    <div className="product">
        <div className="pro-img minHeight">
        {project.projectMainImage != null 
           ? <img src={project?.projectMainImage?.projectGalleryLink} style={{width:225}} className="img-fluid" />
           : <img src={primg1} style={{width:225}} className="img-fluid" />
        }
        </div>
      <div className="bg-transparent border-top card-footer"><div className="contact-links d-flex"><div className="flex-fill font-size-14">{project.city}</div><div className="float-end"><Link to={"/project-detail/" + project.id + "/" + project.projectId}> {project.projectName} </Link></div></div></div>
        
    </div>

  </Col>
))}
 
</Row>





           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Project.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetProject:PropTypes.func,
  projects:PropTypes.array,
  loading:PropTypes.object
}

const mapStateToProps = ({ projects }) => (
    {
        projects:projects.projects,
        loading:projects.loading
  
    })

    const mapDispatchToProps = dispatch => ({
        
        onGetProject:(agetnId) => dispatch(getProjects(agetnId))
       
      });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Project))
