import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, capitalize } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addClientAddListing, getClientAddListing,getListingType } from "../../store/actions"
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import typeView from "../../assets/images/type-view.png"
import uploadView from "../../assets/images/icons/upload.png"
import gridView from "../../assets/images/grid-view.png"
import downloadView from "../../assets/images/icons/down.png"
import compareImg from "../../assets/images/icons/compare.png"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Dropzone from "react-dropzone"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import classnames from "classnames"
import img1 from "../../assets/img/img1.jpg"
import img2 from "../../assets/img/img2.jpg"
import img3 from "../../assets/img/img3.jpg"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Tooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Dropdown,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"

class addListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab1: "1",
      open: false,
      mlsFields: [{ mlsId: "", mlsLink: "", mlsCity: "", mlsNote: "", mlsPrice: "" }]
    }
    this.toggle = this.toggle.bind(this);
    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }
  toggleRightCanvas() {
    this.setState({ isEdit: false, isRight: !this.state.isRight });
  }
  toggle () {
    this.setState({
      open: !this.state.open
    })
  }
  handleAddListingSubmit(value){
    
    this.props.addClientAddListing(value, this.props.history);

  }
  componentDidMount() {
    const {  onGetClientAddListing,onGetListingType } = this.props;
   
    onGetClientAddListing(localStorage.getItem('userId'),localStorage.getItem('agentId'))
    onGetListingType(3)
}
toggle1(tab) {
  if (this.state.activeTab1 !== tab) {
    this.setState({
      activeTab1: tab,
    });
  }
}
handleChange = (activeStatus, itemId) => {
    
};
  render() {
    const { addListings, loading,  listingtypes } = this.props;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Add Listing  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Listings ")}
            />
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    mlsFields: this.state.mlsFields,
                   
                    agentId: localStorage.getItem('agentId'),
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    mlsFields: Yup.array().of(
                      Yup.object().shape({
                        mlsId: Yup.string().required('This is required'),
                        mlsLink: Yup.string().required('This is required'),
                        // mlsCity: Yup.string().required('This is required'),
                        mlsPrice:Yup.number().typeError('Price should be a number')
                      })
                    )
                    
                  })}

                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values.mlsFields);
                  }}
                >
                  {({ errors, touched, values, handleChange, handleBlur }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className=" pb-2">Add new Listing</h5>
                    <FieldArray
                name="mlsFields"
                render={arrayHelpers => (
                  <div>
                    {values.mlsFields.map((mlsField, index) => (
                      <div key={index}>
              
                   <Row>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Unit ID* ( MlS#, etc)</Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsId`}
                              value={mlsField.mlsId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsId &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsId
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsId`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="5">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Link ( MLS link etc)</Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsLink`}
                              value={mlsField.mlsLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsLink &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsLink
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsLink`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="5">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="address" className="label-text mt-3">
                                      {this.props.t("Address *")}
                                    </Label>
                                    <PlacesAutocomplete
                            value={mlsField.listingAddress}
                              onChange={(value) => setFieldValue(`createClientPotentialListingLists.${index}.listingAddress`, value)}
                              onSelect={(value) => this.handlePlaceSelected(value, index, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name={`createClientPotentialListingLists.${index}.listingAddress`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                 
                                  </FormGroup>
                                </Col>      
                        <Col sm="2">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Listed Price </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsPrice`}
                              value={mlsField.mlsPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsPrice &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsPrice
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsPrice`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                                  <FormGroup className="mb-3">
                                    <Label className="label-text mt-3" htmlFor={`createClientPotentialListingLists.${index}.listingType`}>
                                      {this.props.t("Type")}
                                    </Label>
                                    <Field as="select" name={`createClientPotentialListingLists.${index}.listingType`}
                                       className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingType && touched.createClientPotentialListingLists?.[index]?.listingType ? " is-invalid" : "")}

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.id}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingType`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>
                                </Col> 
                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label  className="label-text mt-3" htmlFor={`createClientPotentialListingLists.${index}.listingBedroom`}>
                                      {this.props.t("Bedroom")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingBedroom`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingBedroom && touched.createClientPotentialListingLists?.[index]?.listingBedroom ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listingBedroom`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingBedroom`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label  className="label-text mt-3" htmlFor={`createClientPotentialListingLists.${index}.listingBathroom`}>
                                      {this.props.t("Bathroom")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingBathroom`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingBathroom && touched.createClientPotentialListingLists?.[index]?.listingBathroom ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listingBathroom`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingBathroom`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="1">
                                  <FormGroup  className="mb-3">
                                    <Label className="label-text mt-3" htmlFor={`createClientPotentialListingLists.${index}.listingParking`}>
                                      {this.props.t("Square feet")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingParking`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingParking && touched.createClientPotentialListingLists?.[index]?.listingParking ? " is-invalid" : "")}

                                      id="mls"
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingParking`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>    
                    
                        <Col sm="4">
                        <FormGroup className="mb-3">
                        <Label htmlFor="Notes" className="label-text mt-3">Notes </Label>
                            <input
                              type="text"
                              name={`mlsFields.${index}.mlsNote`}
                              value={mlsField.mlsNote}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.mlsFields &&
                                errors.mlsFields[index] &&
                                errors.mlsFields[index].mlsNote &&
                                touched.mlsFields &&
                                touched.mlsFields[index] &&
                                touched.mlsFields[index].mlsNote
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name={`mlsFields.${index}.mlsNote`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        {index === 0 && (
                            
                            <Col sm="1">  <div className="clearfix">
                              
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push({ mlsId: '', mlsLink: '' })}
                                style={{ marginTop: 42}} className="btn btn-add "
                              >
                                <i className=" bx bx-plus-medical" /> Add More
                              </button>
                              </div>
                            </Col>
                          )}
                           {index !== 0 && (
                            
                            <Col sm="1">  <div className="clearfix">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                style={{ marginTop: 42}} className="btn btn-remove "
                              >
                               <i className=" bx bx-minus" /> Remove

                              </button>
                            </div>
                            </Col>
                          )}

                        {/* <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} className="btn btn-primary w-md" onClick={(e)=>{e.preventDefault()}}>Add More</button></div></Col> */}

                       
                    </Row>
                    </div>
                     ))}
                     <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 2}} type="submit" className="btn btn-primary w-md">Save</button></div></Col>
                     </div>
                      )}
                      />

             </CardBody>
                </Card>
             
                
                </Form>
                  )}
                </Formik>
			  <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                      <Row>
                        <Col sm="6">
                           <h5 className="card-title mb20">Listing History</h5>
                        </Col>
                        <Col sm="6">
                        <div>
                          <div
                            className="btn-group rl-group-btn float-end mb20"
                            role="group"
                          >
                          <Link to="/add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning  w-xs active"
                          >
                            <img src={gridView} /><br/>
                              <span className="text-black">All</span>
                          </button></Link>
                          <Link to="/sent-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img className="tab-img" src={uploadView} />
                             <br/>
                             <span className="text-black">Sent</span>
                              
                          </button></Link>
                          <Link to="/received-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img className="tab-img" src={downloadView} />
                              <br/>
                              
                              <span className="text-black">Received</span>
                          </button></Link>
                          <Link to="/visited-add-listing" >
                          <button
                            type="button"
                            className="btn btn-outline-warning   w-xs"
                          >
                            <img src={typeView} />
                              <br/>
                              <span className="text-black">Visited</span>
                          </button></Link>
                          {/* <button
                            type="button"
                            className="btn btn-outline-warning  w-xs"
                          >
                            <Link to="/buyer-prefrences" className='text-black'><img src={groupView} />
                            <br/>
                            Manage
                            </Link>
                          </button> */}
                              
                             
                            </div>
                          </div>  
                                    </Col>
                                </Row>
                    <div className="row gy-2 gx-3  mb20">
                      <div className="col-sm-3 float-start">
                        <div className="col-sm-12">
                          <Link to="/compare-match">
                          <img className="tab-img" src={compareImg}/> <span className="compare-text"> Compare (Max 4 properties)</span>
                          </Link>
                          
                        </div>
                      </div>
                      <div className="col-sm-9 ">
                        <div className="row float-end">
                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search MLS#" />
                          </div>

                          <div className="col-sm-auto">
                            <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Price" />
                          </div>
                        
                          <div className="col-sm-auto">
                            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                            <select defaultValue="0" className="form-select">
                              <option value="0">Status...</option>
                              <option value="1">Avialable </option>
                              <option value="2"> Sold</option>
                              d
                            </select>
                          </div>
                          
                          <div className="col-sm-auto">
                            <button type="submit" className="btn btn-primary w-md">Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
        <div className="clearfix"></div>
        
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                <th></th>
								<th>Date</th>
								<th>Unit ID</th>
								<th>Asking price</th>
								<th>City</th>
                                <th>Status </th>
                                {/* <th>Like / Dislike </th> */}
                                <th>Showing</th>
                                {/* <th>Manage</th> */}
                                <th>Action</th>
                                
							  </tr>
							</thead>
              <tbody>
                              {map(addListings, (addListing, lkey) => (
                                 <tr key={"_alist_" + lkey}>
                                  {/* <td><input type='checkbox' name='checkbox'/></td>
                                    <td>{addListing.addedBy == 'client' ? <img className="listing-img" src={uploadView} /> : <img className="listing-img" src={downloadView} /> } <Moment format="DD MMM, YYYY">{addListing.listingDate}</Moment></td>
                                    <td><a target="_blank" rel="noreferrer"  href={addListing.mlsurl}>{addListing.mlsId} <i className=" bx bx-link-external"></i></a></td>
                                    <td><CurrencyFormat value={addListing.listedPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                    <td>{addListing.listingCity}</td>
                                    <td>{addListing.listingStatusId==0 ? "Sold" : "Available"}</td>
                                 
                                   
                                    
                                    <td>
                                  
                                    
                                    
                                    </td> */}
                                </tr>
                              ))}
                              <tr>
                              <td><input type='checkbox' name='checkbox'/></td>
                                    <td>20th Oct </td>
                                    <td> <Link to="/manage-listing">MLS 34578976<i className=" bx bx-link-external"></i></Link></td>
                                    <td>$ 1,000,450</td>
                                    <td>Toronto</td>
                                    <td> Available </td>
                                  
                                    <td> N/A</td>
                                    
                                    <td>
                                     <Link to="#" onClick={this.toggleRightCanvas} className="btn btn-manage">Manage</Link>
                                    </td>
                              </tr>

                      
                              </tbody>          
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
           
          </Container>

          <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleRightCanvas}
          style={{width:650}}
        >
          <OffcanvasHeader toggle={this.toggleRightCanvas}>
            Manage
          </OffcanvasHeader>
          <OffcanvasBody>
            <Nav pills className="navtab-bg nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "1",
                  })}
                  onClick={() => {
                    this.toggle1("1");
                  }}
                >
                  Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "2",
                  })}
                  onClick={() => {
                    this.toggle1("2");
                  }}
                >
                  Insights &  review
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "3",
                  })}
                  onClick={() => {
                    this.toggle1("3");
                  }}
                >
                  Message
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "4",
                  })}
                  onClick={() => {
                    this.toggle1("4");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeTab1}
              className="p-3 text-muted"
            >
              <TabPane tabId="1">
                <div className="tabContent info-listing">
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Unit Id</h5> 
                        </Col>
                        <Col sm="6">
                              <h6>MLS 34578976  <span className="float-end"><i className=" bx bx-map-alt font-size-18"></i> {" "}<i className="bx bx-link-external font-size-18"></i></span></h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Sent/Received date</h5> 
                        </Col>
                        <Col sm="6">
                            <h6>20th Oct </h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>View this property?</h5> 
                        </Col>
                        <Col sm="6">
                        <h6>Yes</h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Property  Status</h5> 
                        </Col>
                        <Col sm="6">
                        <h6>Available</h6> 
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Last updated</h5> 
                        </Col>
                        <Col sm="6">
                        <h6>18th Oct </h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Visit date</h5> 
                        </Col>
                        <Col sm="6">
                        <h6>22th Oct @ 3:00 PM  <span className="float-end"><Link to="#"  onClick={() => {
                    this.toggle1("3");
                  }}>Re-schedule</Link></span></h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h4>Property Details</h4> 
                        </Col>
                        <Col sm="6">
                         
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Address</h5> 
                        </Col>
                        <Col sm="6">
                         
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                          <h5>1815 Ironstone Manor, Pickering, On L1X0C6</h5> 
                        </Col>
                       
                    </Row>

                    <Row className="mb-2">
                        <Col sm="4">
                          <h4>Type</h4> 
                        </Col>
                        <Col sm="4">
                          <h4>Price</h4> 
                        </Col>
                        <Col sm="4">
                          <h4>Square feet</h4> 
                        </Col>
                        <Col sm="4">
                          <h6>Condo</h6> 
                        </Col>
                        <Col sm="4">
                          <h6>$ 1,000,450</h6> 
                        </Col>
                        <Col sm="4">
                        <h6>1200</h6> 
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          <h4>Bedrooms</h4> 
                        </Col>
                        <Col sm="6">
                          <h4>Bathrooms</h4> 
                        </Col>
                        <Col sm="6">
                          <h6>3</h6> 
                        </Col>
                        <Col sm="6">
                        <h6>3</h6>  
                        </Col>
                        
                    </Row>

                    <Row className="mb-2">
                        <Col sm="6">
                          <h5>Notes</h5> 
                        </Col>
                        <Col sm="6">
                         
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="6">
                          
                        </Col>
                        
                    </Row>
                </div>
              </TabPane>
            

              <TabPane tabId="2">
                <div className="tabContent info-listing">
                    <Row className="mb-2">
                        <Col sm="6">
                          <h4>Property Features</h4> 
                        </Col>
                        <Col sm="6">
                         
                        </Col>
                    </Row>
                  
                    <Formik
                    enableReinitialize={true}
                    initialValues={{

                      f1 : 1,
                      f2 : 1000450,
                      listingCity:'Toronto',
                      listingBedroom:3,
                      listingBathroom:3,
                      listingParking:1200,
                      f3:'The house had a very beautiful front..neighborhood semed nice with kids playing around. Good school close by.',
                      f4:'Little bit farher from the highway. '

                     
                    }}
                    validationSchema={Yup.object().shape({
                   
                    

                    })}

                    onSubmit=""

                  >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                         
                        <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingType">
                                {this.props.t("Type")}
                              </Label>
                              <Field as="select" name="f1"
                                      className={
                                        "form-control" +
                                        (errors.f1 && touched.f1
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.id}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                              <ErrorMessage
                                name="listingType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingCity">
                                {this.props.t("Price")}
                              </Label>
                              <Field
                                name="f2"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingCity && touched.listingCity
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingCity"
                              />
                              <ErrorMessage
                                name="listingCity"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingCity">
                                {this.props.t("City")}
                              </Label>
                              <Field
                                name="listingCity"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingCity && touched.listingCity
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingCity"
                              />
                              <ErrorMessage
                                name="listingCity"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                    
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingBedroom">
                                {this.props.t("Bedroom")}
                              </Label>
                              <Field
                                name="listingBedroom"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingBedroom && touched.listingBedroom
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingBedroom"
                              />
                              <ErrorMessage
                                name="listingBedroom"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingBathroom">
                                {this.props.t("Bathroom")}
                              </Label>
                              <Field
                                name="listingBathroom"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingBathroom && touched.listingBathroom
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingBathroom"
                              />
                              <ErrorMessage
                                name="listingBathroom"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingParking">
                                {this.props.t("Square feet")}
                              </Label>
                              <Field
                                name="listingParking"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingParking && touched.listingParking
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingParking"
                              />
                              <ErrorMessage
                                name="listingParking"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="12">
                          <h4>Custom features items</h4> 
                        </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="notes">
                                {this.props.t("What I liked")}
                              </Label>
                              <Field
                                name="f3"
                                onChange={handleChange}
                                as="textarea"

                                className={
                                  "form-control" +
                                  (errors.f3 && touched.f3
                                    ? " is-invalid"
                                    : "")
                                }

                                id="f3"
                              />
                              <ErrorMessage
                                name="f3"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="f4">
                                {this.props.t("What I did not like")}
                              </Label>
                              <Field
                                name="f4"
                                onChange={handleChange}
                                as="textarea"

                                className={
                                  "form-control" +
                                  (errors.f4 && touched.f4
                                    ? " is-invalid"
                                    : "")
                                }

                                id="notes"
                              />
                              <ErrorMessage
                                name="notes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>          
                        </Row>
                        <Row>

                          <div className="clearFix">
                          <button type="button" className="btn btn-primary">Save</button>
                            
                           </div>

                        </Row>
                      </Form>
                    )}
                  </Formik>
                    
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="tabContent">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                      clientId: 0,
                      agentId: 0,
                      mlsId: (this.state && this.state.mlsId) || "",
                      mlsurl: (this.state && this.state.mlsurl) || "",
                      listingDate: new Date(),
                      listingStatusId: 1,
                      showingType:"1",
                      isInterested: true,
                      isActive: true,
                      addedBy: 'agent',
                      listedPrice: (this.state && this.state.listedPrice) || "",
                      listingCity: (this.state && this.state.listingCity) || "",
                      notes: (this.state && this.state.notes) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      //mlsId: Yup.string().required("This is Required"),
                      mlsurl: Yup.string().required("This is Required"),
                      listedPrice: Yup.number().typeError('Price should be a number').required("This is Required")

                    })}

                    onSubmit={this.handleAddListingSubmit}

                  >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>

                        <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Type Of Activity")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">
                          <Field type="radio" className="btn-check" onChange={handleChange}  name="showingType" id="btnradio4" value="1" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio4">New</label>

                          {/* <Field type="radio" className="btn-check" onChange={handleChange}       name="showingType" id="btnradio5" value="2" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Schedule listing</label> */}

                          <Field type="radio" className="btn-check" onChange={handleChange} name="showingType" id="btnradio6"  value="3" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio6">Re-schedule listing</label>
                         
                          
                        </div>
                        <ErrorMessage
                          name="activityType"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                        </Col>  
                        { values.showingType != '1' && values.showingType != '2' && values.showingType != '3' && (
                          <>
                    <Col sm="4">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="showingDate">
                                    {this.props.t("Schedule Date ")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb15">

                      <Label htmlFor="startTime">
                        {this.props.t("Start Time")}
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          value={values.startTime}
                          onChange={(value) => setFieldValue('startTime', value[0])}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i"
                          }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>

                      </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.endTime}
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>
                      </>
                        )}
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="notes">
                                {this.props.t("Enter your message")}
                              </Label>
                              <Field
                                name="notes"
                                onChange={handleChange}
                                as="textarea"

                                className={
                                  "form-control" +
                                  (errors.notes && touched.notes
                                    ? " is-invalid"
                                    : "")
                                }

                                id="mls"
                              />
                              <ErrorMessage
                                name="notes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                        </Row>
                        <Row>

                          <div className="clearFix">

                            <button type="submit" className="btn btn-primary w-md  mt20" name="addActivity" id="addActivity"> {this.props.t("Send")}</button>{" "}
                           
                           </div>

                        </Row>
                      </Form>
                    )}
                  </Formik>

                    <div style={{marginTop:40}}>
                  <Table className="table table-sm m-0">
                      <Tr>
                        <Th>Date: 10th July (Recieved)</Th>
                      </Tr>
                      <Tr>
                        <Td>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, 
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>&nbsp;</Td>
                      </Tr>
                      <Tr>
                        <Th>Date: 10th July (Sent)</Th>
                      </Tr>
                      <Tr>
                        <Td>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, 
                        </Td>
                      </Tr>
                  </Table>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="tabContent">
                <Dropzone onDrop={this.handleAcceptedFiles}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
                </Dropzone>

                <Row className="mb-3" style={{marginTop:20}}> 
                  <Col sm="6">
                    <img src={img1} className="img-fluid"></img>
                  </Col>
                  <Col sm="6">
                    <img src={img2} className="img-fluid"></img>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="6">
                    <img src={img3} className="img-fluid"></img>
                  </Col>
                  <Col sm="6">
                    
                  </Col>
                </Row>
              
                </div>
              </TabPane>
            </TabContent>

          </OffcanvasBody>
        </Offcanvas>
        </div>
      </React.Fragment>
    )
  }
}
addListing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAddListing:PropTypes.func,
  addListings:PropTypes.array,
  onGetClientAddListing:PropTypes.func,
  loading:PropTypes.object,
  listingtypes:PropTypes.array,
  onGetListingType:PropTypes.func

}
const mapStateToProps = ({ addListing, AppoinmentObject }) => (
  {
    addListings:addListing.addListings,
    loading:addListing.loading,
    listingtypes:AppoinmentObject.listingtypes,
  })
const mapDispatchToProps = dispatch => ({
  
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing:(clientId, agentId) => dispatch(getClientAddListing(clientId, agentId)),
  onGetListingType: agentId => dispatch(getListingType(agentId)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(addListing))
